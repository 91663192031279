import { Modal, Spin } from "antd";
import React, { useCallback, useState } from "react";
import { FloorplanByIdQuery } from "../../generated/types";
import {
  BIM_ORIGIN_X_TOOLTIP_TEXT,
  BIM_ORIGIN_Y_TOOLTIP_TEXT,
  BIM_ORIGIN_Z_TOOLTIP_TEXT,
  FLOOR_HEIGHT_TOOLTIP_TEXT,
  TRUE_NORTH_TOOLTIP_TEXT,
} from "./ImportFloorplanModal/ImportFloorplanModal";
import { InputRow } from "./ImportFloorplanModal/SetPointRow";
import { FloorplanEditPropertiesInput } from "./hooks/useFloorplan";

const BIM_ORIGIN_X = "BIM Origin X";
const BIM_ORIGIN_Y = "BIM Origin Y";
const BIM_ORIGIN_Z = "BIM Origin Z";
const FLOOR_HEIGHT = "Floor Height";
const ANGLE_TO_TRUE_NORTH = "Angle to True North";
const BIM_IDS = "BIM Floor IDs";
const BIM_PROJECT_NAME = "BIM Project ID";

interface EditPropertiesModalProps {
  floorplan: FloorplanByIdQuery["floorplans"][0];
  onClose: () => void;
  editFloorplanProperties: (
    floorplan: Omit<FloorplanEditPropertiesInput, "id">,
  ) => Promise<void>;
  spaceName: string;
  uploadInProgress: boolean;
}

export const EditPropertiesModal = (props: EditPropertiesModalProps) => {
  const {
    onClose,
    editFloorplanProperties,
    spaceName,
    uploadInProgress,
    floorplan,
  } = props;

  const {
    bimOriginX: currentBimOriginX,
    bimOriginY: currentBimOriginY,
    bimOriginZ: currentBimOriginZ,
    floorHeight: currentFloorHeight,
    angleToTrueNorth: currentAngleToTrueNorth,
    bimProjectId: currentBimProjectId,
    bimFloorIds: currentBimFloorIds,
  } = floorplan;
  const [bimOriginX, setBimOriginX] = useState<number | undefined>(
    currentBimOriginX ?? undefined,
  );
  const [bimOriginY, setBimOriginY] = useState<number | undefined>(
    currentBimOriginY ?? undefined,
  );
  const [bimOriginZ, setBimOriginZ] = useState<number | undefined>(
    currentBimOriginZ ?? undefined,
  );
  const [floorHeight, setFloorHeight] = useState<number | undefined>(
    currentFloorHeight ?? undefined,
  );
  const [angleToTrueNorth, setAngleToTrueNorth] = useState<number>(
    currentAngleToTrueNorth ?? 0,
  );
  const [bimFloorIds, setBimFloorIds] = useState<string>(
    currentBimFloorIds?.join(",") ?? "",
  );
  const [bimProjectId, setBimProjectId] = useState<string>(
    currentBimProjectId ?? "",
  );

  const SPECKLE_VIEWER_BASE_URL =
    process.env.SPECKLE_VIEWER_BASE_URL || "https://speckle.disperse.io";

  const onConfirm = useCallback(() => {
    const editedFloorplan = {
      bimOriginX,
      bimOriginY,
      bimOriginZ:
        bimOriginZ === undefined &&
        bimOriginY !== undefined &&
        bimOriginX !== undefined
          ? 0
          : bimOriginZ,
      floorHeight,
      angleToTrueNorth,
      bimFloorIds,
      bimProjectId,
    };
    editFloorplanProperties(editedFloorplan);
  }, [
    bimOriginX,
    bimOriginY,
    bimOriginZ,
    floorHeight,
    angleToTrueNorth,
    bimFloorIds,
    bimProjectId,
    editFloorplanProperties,
  ]);

  const previewUrl = `${SPECKLE_VIEWER_BASE_URL}/projects/${
    encodeURIComponent(bimProjectId) || "<project-id>"
  }/models/${encodeURIComponent(bimFloorIds) || "<floor-ids-comma-separated>"}`;

  return (
    <Modal
      bodyStyle={{ height: "45vh", overflowY: "auto" }}
      width={"30vw"}
      title={`Edit floorplan for ${spaceName}`}
      closable={false}
      visible={true}
      onCancel={onClose}
      destroyOnClose={true}
      onOk={onConfirm}
      okText={"Confirm"}
    >
      <Spin spinning={uploadInProgress} tip="Upload in progress...">
        <InputRow
          name={BIM_ORIGIN_X}
          value={bimOriginX}
          onSet={setBimOriginX}
          unit={"mm"}
          tooltipText={BIM_ORIGIN_X_TOOLTIP_TEXT}
        />
        <InputRow
          name={BIM_ORIGIN_Y}
          value={bimOriginY}
          onSet={setBimOriginY}
          unit={"mm"}
          tooltipText={BIM_ORIGIN_Y_TOOLTIP_TEXT}
        />
        <InputRow
          name={BIM_ORIGIN_Z}
          value={bimOriginZ}
          onSet={setBimOriginZ}
          unit={"mm"}
          tooltipText={BIM_ORIGIN_Z_TOOLTIP_TEXT}
        />
        <InputRow
          name={FLOOR_HEIGHT}
          value={floorHeight}
          onSet={setFloorHeight}
          unit={"mm"}
          tooltipText={FLOOR_HEIGHT_TOOLTIP_TEXT}
        />
        <InputRow
          name={ANGLE_TO_TRUE_NORTH}
          value={angleToTrueNorth}
          onSet={setAngleToTrueNorth}
          unit={"degrees"}
          tooltipText={TRUE_NORTH_TOOLTIP_TEXT}
        />
        <InputRow
          name={BIM_PROJECT_NAME}
          value={bimProjectId}
          onSet={setBimProjectId}
          unit={""}
          tooltipText="Enter the BIM project ID."
          type="string"
        />
        <InputRow
          name={BIM_IDS}
          value={bimFloorIds}
          onSet={setBimFloorIds}
          unit={""}
          tooltipText="Enter BIM FloorIDs, separated by commas."
          type="string"
        />
        <div
          style={{
            marginTop: "16px",
            padding: "8px",
            backgroundColor: "#f0f0f0",
            borderRadius: "4px",
          }}
        >
          <strong>Preview URL:</strong>
          <div>
            <a href={previewUrl} target="_blank" rel="noopener noreferrer">
              {previewUrl}
            </a>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
