// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".module__header--1Hjsw{font-size:large;font-weight:bolder}.module__status--ivCql{width:fit-content}", "",{"version":3,"sources":["webpack://./src/components/IsProjectReleasedToggle/module.css"],"names":[],"mappings":"AAAA,uBACI,eAAA,CACA,kBAAA,CAGJ,uBACI,iBAAA","sourcesContent":[".header {\n    font-size: large;\n    font-weight: bolder;\n}\n\n.status {\n    width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "module__header--1Hjsw",
	"status": "module__status--ivCql"
};
export default ___CSS_LOADER_EXPORT___;
