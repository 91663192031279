import { Spin, Switch } from "antd";
import React from "react";
import {
  GetProjectConfigQuery,
  useGetProjectConfigQuery,
  useUpdateIsReleasedProjectConfigMutation,
} from "../../generated/types";
import pageStyles from "../../pages/pageStyles.module.css";
import { Project } from "../../types";
import styles from "./module.css";

const POLL_INTERVAL = 1000 * 60; // 1 minute

const getStatus = (
  loading: boolean,
  data?: GetProjectConfigQuery,
  onChange?: (checked: boolean) => void,
) => {
  if (loading) {
    return <Spin />;
  }
  return (
    <Switch
      checked={data?.projects[0].config.isReleased ?? false}
      onChange={onChange}
    />
  );
};

export const IsProjectReleasedToggle = ({ project }: { project: Project }) => {
  const { data, loading, refetch } = useGetProjectConfigQuery({
    variables: {
      customer: project.customer,
      project: project.project,
    },
    pollInterval: POLL_INTERVAL,
  });

  const [updateProjectConfig] = useUpdateIsReleasedProjectConfigMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const handleToggle = async (checked: boolean) => {
    try {
      await updateProjectConfig({
        variables: {
          customer: project.customer,
          project: project.project,
          isReleased: checked,
        },
      });
    } catch (error) {
      console.error("Failed to update project config:", error);
    }
  };

  return (
    <div className={pageStyles["project-status-container"]}>
      <header className={styles.header}>
        Project Release Status (Openspace)
      </header>
      <div className={styles.status}>
        {getStatus(loading, data, handleToggle)}
      </div>
    </div>
  );
};
