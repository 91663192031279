import { Divider, Modal, Spin } from "antd";
import cx from "classnames";
import _ from "lodash";
import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useNotification } from "../../../contexts/Notifications";
import { useKeyEvent } from "../../../pages/SpatialConfigModePage/hooks/useKeyEvent";
import { DrawingTool } from "../../DrawingTool/DrawingTool";
import {
  STROKE_SIZE_RATIO,
  euclidianDistance,
} from "../../DrawingTool/drawing-utils";
import { Crosshair } from "../../DrawingTool/shapes";
import { CrosshairNode } from "../../DrawingTool/types";
import { FileUploader } from "../../FileUploader";
import { Drawing } from "../../SpatialConfigurationMode/types";
import { FloorplanCreateInput } from "../hooks/useFloorplan";
import styles from "./ImportFloorplanModal.module.css";
import { ImportFloorplanModalHeader } from "./ImportFloorplanModalHeader";
import { InputRow, SetPointRow } from "./SetPointRow";

const IMPORT_FLOORPLAN_TEXT = "Import floor plan";
const IMPORT_FLOORPLAN_KEY_MODIFIER = "e";

const PROPERTIES_TITLE = "Properties";
const FLOORPLAN_TITLE = "Floorplan";
const BIM_CONFIGURATION_TITLE = "BIM Configuration";

const SCALE_DISTANCE = "Scale Distance";
const BIM_ORIGIN_X = "BIM Origin X";
const BIM_ORIGIN_Y = "BIM Origin Y";
const BIM_ORIGIN_Z = "BIM Origin Z";
const FLOOR_HEIGHT = "Floor Height";
const ANGLE_TO_TRUE_NORTH = "Angle to True North";
const BIM_PROJECT_ID = "BIM Project ID";
const BIM_FLOOR_IDS = "BIM Floor IDs";

const ORIGIN_TOOLTIP_TEXT =
  "Click “set”, hold “e” on your keyboard, and pick the origin point on the floorplan drawing";
export const BIM_ORIGIN_X_TOOLTIP_TEXT =
  "Enter BIM X Coordinate of the origin point (in mm)";
export const BIM_ORIGIN_Y_TOOLTIP_TEXT =
  "Enter BIM Y Coordinate of the origin point (in mm)";
export const BIM_ORIGIN_Z_TOOLTIP_TEXT =
  "Enter BIM Z Coordinate of the origin point (in mm)";
export const FLOOR_HEIGHT_TOOLTIP_TEXT = "Enter floor height (in mm)";
export const TRUE_NORTH_TOOLTIP_TEXT =
  "Enter the difference between Project North and True North + the difference in the orientation of the drawing compared to the BIM model (if any)";
const SCALE_START_TOOLTIP_TEXT =
  "Find a measurement on the drawing, click “set”, hold “e” on your keyboard, and select one end of it for scale start";
const SCALE_END_TOOLTIP_TEXT =
  "Click “set”, hold “e” on your keyboard, and select another point of the measurement as the scale end";
const SCALE_DISTANCE_TOOLTIP_TEXT =
  "Enter the value between Scale Start and Scale End (in mm)";
const BIM_PROJECT_ID_TOOLTIP_TEXT = "Enter the BIM Project ID (if any)";
const BIM_FLOOR_IDS_TOOLTIP_TEXT = "Enter the BIM Floor IDs (if any)";

const SPECKLE_VIEWER_BASE_URL =
  process.env.SPECKLE_VIEWER_BASE_URL || "https://speckle.disperse.io";

export enum InputMode {
  ORIGIN = "Origin",
  SCALE_START = "Scale Start",
  SCALE_END = "Scale End",
}

type DrawingParameters = {
  [InputMode.ORIGIN]: CrosshairNode | undefined;
  [InputMode.SCALE_END]: CrosshairNode | undefined;
  [InputMode.SCALE_START]: CrosshairNode | undefined;
};

const INITIAL_DRAWING_PARAMETERS = {
  [InputMode.ORIGIN]: undefined,
  [InputMode.SCALE_END]: undefined,
  [InputMode.SCALE_START]: undefined,
};

const DRAWING_NODE_COLORS = {
  [InputMode.ORIGIN]: "red",
  [InputMode.SCALE_END]: "green",
  [InputMode.SCALE_START]: "blue",
};

const INPUT_MODE_TOOLTIPS = {
  [InputMode.ORIGIN]: ORIGIN_TOOLTIP_TEXT,
  [InputMode.SCALE_START]: SCALE_START_TOOLTIP_TEXT,
  [InputMode.SCALE_END]: SCALE_END_TOOLTIP_TEXT,
};

const SVG_CIRCLE_SIZE_RATIO = 150;
interface ImportFloorplanModalProps {
  onClose: () => void;
  spaceId: string;
  spaceName: string;
  createFloorplan: (floorplan: FloorplanCreateInput) => Promise<void>;
  uploadInProgress: boolean;
}

export const ImportFloorplanModal = (props: ImportFloorplanModalProps) => {
  const { onClose, spaceId, spaceName, createFloorplan, uploadInProgress } =
    props;
  const isBimEnabled = useMemo(() => true, []);
  const notify = useNotification();
  const [drawing, setDrawing] = useState<Drawing>();
  const [imageFile, setImageFile] = useState<File>();

  const [inputMode, setInputMode] = useState<InputMode>();
  const [drawingParameters, setDrawingParameters] = useState<DrawingParameters>(
    INITIAL_DRAWING_PARAMETERS,
  );
  const [scaleDistance, setScaleDistance] = useState<number>();

  const [bimOriginX, setBimOriginX] = useState<number>();
  const [bimOriginY, setBimOriginY] = useState<number>();
  const [bimOriginZ, setBimOriginZ] = useState<number>();
  const [floorHeight, setFloorHeight] = useState<number>();
  const [angleToTrueNorth, setAngleToTrueNorth] = useState<number>();
  const [bimProjectId, setBimProjectId] = useState<string>();
  const [bimFloorIds, setBimFloorIds] = useState<string[]>();

  const { keyEvent, onKeyUp, onKeyDown } = useKeyEvent();

  const onConfirm = useCallback(() => {
    const scaleStart = drawingParameters[InputMode.SCALE_START];
    const scaleEnd = drawingParameters[InputMode.SCALE_END];
    const origin = drawingParameters[InputMode.ORIGIN];

    if (
      scaleStart &&
      scaleEnd &&
      origin &&
      scaleDistance &&
      drawing &&
      imageFile
    ) {
      const floorplan = {
        spaceId: spaceId,
        originX: origin.x,
        originY: origin.y,
        width: drawing.width,
        height: drawing.height,
        mmScaleFactor:
          scaleDistance /
          euclidianDistance(
            { x: scaleStart.x, y: scaleStart.y },
            { x: scaleEnd.x, y: scaleEnd.y },
          ),
        floorplanFile: imageFile,
        bimOriginX,
        bimOriginY,
        bimOriginZ: bimOriginZ || 0,
        floorHeight,
        angleToTrueNorth,
        bimProjectId,
        bimFloorIds,
      };
      createFloorplan(floorplan);
    } else {
      notify("Please set all parameters", "warning");
    }
  }, [
    drawingParameters,
    scaleDistance,
    drawing,
    imageFile,
    spaceId,
    bimOriginX,
    bimOriginY,
    bimOriginZ,
    floorHeight,
    angleToTrueNorth,
    bimProjectId,
    bimFloorIds,
    createFloorplan,
    notify,
  ]);

  const handleDrawingClick = (x: number, y: number) => {
    if (inputMode && drawing && keyEvent === IMPORT_FLOORPLAN_KEY_MODIFIER) {
      setDrawingParameters((prev) => ({
        ...prev,
        [inputMode]: {
          x,
          y,
          color: DRAWING_NODE_COLORS[inputMode],
          size: drawing.width / SVG_CIRCLE_SIZE_RATIO,
          strokeWidth:
            drawing.width / SVG_CIRCLE_SIZE_RATIO / STROKE_SIZE_RATIO,
        },
      }));
    }
  };

  const onImport = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length !== 1) {
        notify(
          "Upload failed, we currently only support uploading one file at a time",
        );
      } else {
        const file = acceptedFiles[0];
        setImageFile(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          if (typeof e.target?.result === "string") {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
              setDrawing({
                url: img.src,
                originX: 0,
                originY: 0,
                mmScaleFactor: 1,
                width: img.width,
                height: img.height,
              });
            };
          }
        };
      }
    },
    [notify],
  );

  const nodes: CrosshairNode[] = useMemo(
    () => _.compact(Object.values(drawingParameters)),
    [drawingParameters],
  );

  const previewUrl = `${SPECKLE_VIEWER_BASE_URL}/projects/${
    encodeURIComponent(bimProjectId ?? "") || "<project-id>"
  }/models/${
    encodeURIComponent(bimFloorIds?.join(",") ?? "") ||
    "<floor-ids-comma-separated>"
  }`;

  return (
    <div onKeyPress={onKeyDown} onKeyUp={onKeyUp} tabIndex={0}>
      <Modal
        bodyStyle={{ height: "80vh" }}
        width={"90vw"}
        title={<ImportFloorplanModalHeader floorName={spaceName} />}
        closable={false}
        visible={true}
        onCancel={onClose}
        destroyOnClose={true}
        onOk={onConfirm}
        okText={"Confirm"}
      >
        <Spin spinning={uploadInProgress} tip="Upload in progress...">
          {drawing ? (
            <div className={styles["modal-body"]}>
              <div className={styles["drawing-tool"]}>
                <DrawingTool
                  nodes={nodes.map((node) => (
                    <Crosshair key={node.id} node={node} />
                  ))}
                  drawing={drawing}
                  onDrawingClick={handleDrawingClick}
                />
              </div>
              <div className={styles["set-point-box"]}>
                <div
                  className={cx(styles["text__centred"], styles["text__title"])}
                >
                  {PROPERTIES_TITLE}
                </div>
                <GroupedProperties title={FLOORPLAN_TITLE}>
                  {Object.values(InputMode).map((mode, index) => (
                    <SetPointRow
                      key={index}
                      tooltipText={INPUT_MODE_TOOLTIPS[mode]}
                      inputMode={mode}
                      value={drawingParameters[mode]}
                      onSet={setInputMode}
                      activeInputMode={inputMode}
                    />
                  ))}
                  <InputRow
                    name={SCALE_DISTANCE}
                    value={scaleDistance}
                    onSet={setScaleDistance}
                    unit={"mm"}
                    tooltipText={SCALE_DISTANCE_TOOLTIP_TEXT}
                  />
                </GroupedProperties>
                {isBimEnabled && (
                  <GroupedProperties title={BIM_CONFIGURATION_TITLE}>
                    <InputRow
                      name={BIM_ORIGIN_X}
                      value={bimOriginX}
                      onSet={setBimOriginX}
                      unit={"mm"}
                      tooltipText={BIM_ORIGIN_X_TOOLTIP_TEXT}
                    />
                    <InputRow
                      name={BIM_ORIGIN_Y}
                      value={bimOriginY}
                      onSet={setBimOriginY}
                      unit={"mm"}
                      tooltipText={BIM_ORIGIN_Y_TOOLTIP_TEXT}
                    />
                    <InputRow
                      name={BIM_ORIGIN_Z}
                      value={bimOriginZ}
                      onSet={setBimOriginZ}
                      unit={"mm"}
                      tooltipText={BIM_ORIGIN_Z_TOOLTIP_TEXT}
                    />
                    <InputRow
                      name={FLOOR_HEIGHT}
                      value={floorHeight}
                      onSet={setFloorHeight}
                      unit={"mm"}
                      tooltipText={FLOOR_HEIGHT_TOOLTIP_TEXT}
                    />
                    <InputRow
                      name={ANGLE_TO_TRUE_NORTH}
                      value={angleToTrueNorth}
                      onSet={setAngleToTrueNorth}
                      unit={"degrees"}
                      tooltipText={TRUE_NORTH_TOOLTIP_TEXT}
                    />
                    <InputRow
                      name={BIM_PROJECT_ID}
                      value={bimProjectId}
                      onSet={setBimProjectId}
                      unit={""}
                      tooltipText={BIM_PROJECT_ID_TOOLTIP_TEXT}
                      type="string"
                    />
                    <InputRow
                      name={BIM_FLOOR_IDS}
                      value={bimFloorIds?.join(",")}
                      onSet={(value) => setBimFloorIds(value.split(","))}
                      unit={""}
                      tooltipText={BIM_FLOOR_IDS_TOOLTIP_TEXT}
                      type="string"
                    />
                    <div
                      style={{
                        marginTop: "16px",
                        padding: "8px",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "4px",
                      }}
                    >
                      <strong>Preview URL:</strong>
                      <div>
                        <a
                          href={previewUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {previewUrl}
                        </a>
                      </div>
                    </div>
                  </GroupedProperties>
                )}
              </div>
            </div>
          ) : (
            <FileUploader
              title={IMPORT_FLOORPLAN_TEXT}
              onFilesSelected={onImport}
            />
          )}
        </Spin>
      </Modal>
    </div>
  );
};

const GroupedProperties: React.FC<PropsWithChildren<{ title: string }>> = ({
  title,
  children,
}) => (
  <React.Fragment>
    <Divider>
      <div className={cx(styles["text__centred"], styles["text__subtitle"])}>
        {title}
      </div>
    </Divider>
    {children}
  </React.Fragment>
);
